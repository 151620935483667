import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

import modifyMyWishStatus from '@/composables/Profile/modifyMyWishStatus';

// pending is only for admin usage. In public use, alumni only have 2 decisions: granted and declined
const now = new firebase.firestore.Timestamp.now().toDate();

// candidateId: firebase collection("Candidates") id
// candidateUid: user.value.uid
const changeWishStatus = async (companyId, wishId, candidateId, candidateUid, alumniId, decision) => {
  try {
    if(decision=="granted"){
      await db.collection("Profiles").doc(alumniId).collection("myAcceptedWishes").add({
        studentId: candidateUid,
        companyId: companyId,
        wishId: wishId,
        status: decision,
        time: now
      });
    }

    // Admin use
    if(decision=="pending"){
      await db.collection("Profiles").doc(alumniId).collection("myAcceptedWishes").where("wishId", "==", wishId).get().then(async docs =>{
        let docRef = null
        docs.forEach((doc)=>{
          docRef = doc.ref
        })
        if (docRef) {
          await docRef.delete()
        }
      });
    }

    let docRef = db.collection('Companies').doc(companyId).collection("Wishes").doc(wishId);
    await docRef.get().then(async doc => {
      await doc.ref.update({
        isGranted: doc.data().isGranted ? true : decision=="granted",
        lastActivityTime: now
      })
    })
    
    await docRef.collection("Candidates").doc(candidateId).get().then(async doc =>{
      let declinedByArr = [];
      if(doc.data().declinedBy){
        declinedByArr = doc.data().declinedBy;
      }
      if(decision=="declined"){
        declinedByArr.push(alumniId);
      }
      if(decision=="pending" && declinedByArr.includes(alumniId)){
        const index = declinedByArr.indexOf(alumniId);
        if (index > -1) {
          declinedByArr.splice(index, 1);
        }
        // console.log("declinedByArr: "+ declinedByArr)
      }
      await doc.ref.update({
        // declined is tailored to individual alumni, to general public pending remains
        status: decision=="declined" ? "pending" : decision,
        acceptedBy: decision=="granted" ? alumniId : null,
        grantTime: now,
        declinedBy: declinedByArr
      })
    })

    await modifyMyWishStatus(candidateUid, wishId, decision, alumniId);

  } catch (error) {
    alert("changeWishStatus " + error);
  }
};

export default changeWishStatus
