import { db } from '@/firebase/config'
import { ref } from "vue"
import getProfile from '../Profile/getProfile'
import getUser from "@/composables/getUser";

const { user } = getUser();

const getWishCandidates = async (store, companyId, wishId) => {
  try {
    await db.collection("Companies").doc(companyId).collection("Wishes").doc(wishId).collection("Candidates").get().then(docs => {
      let result = [];
      let profile = ref(null);
      let promises = [];
      docs.forEach(doc => {
        promises.push(getProfile(profile, doc.data().uid).then(_ => {
          let temp = { ...profile.value, ...doc.data(), id: doc.id };
          if(temp.declinedBy && temp.declinedBy.includes(user.value.uid)){
            temp.status = "declined";
          }
          result.push(temp);
        }));
      });
      return Promise.all(promises).then(_ => {store.value = result});
    })
  } catch (error) {
    alert("getWishCandidates: " + error);
  }
};

export default getWishCandidates
